@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
p {
  font-family: "Poppins";
}

.homeStructure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100vw;
  height: 100vh;

}

.menuRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 96vw;
}

.rightButtons {
  align-items: center;
  display: flex;
  
}

.manageSub {
  width: 175px;
  margin-right: 15px;
}

.signOut{
  color: red;
  padding-right: 15px;
  cursor: pointer;
}

.logo {
  font-size: 25px;
  font-weight: bold;
  padding-left: 15px;
  cursor: pointer;
}

.yourCampains {
  padding-left: 10px;
  padding-right: 10px;
  width: 95%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 15px;
}

.title {
  font-weight: bold;
  font-size: 25px;
}

.campainTile {
  margin-bottom: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.campainActive, .campainPause {
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.campainActive {
  background-color: #c7f9cc;
  color: #006400;
}

.campainPause {
  background-color: #f7d2c4;
  color: #6a040f;
}

.createCampainBtn {
  margin-bottom: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-style: dashed;
  color: grey;
  cursor: pointer;
}

.createCampainTile {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.createCampainTitle {
  font-size: 20px;
  font-weight: bold;
}

.formElement {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.campNameInput {
  height: 30px;
  margin-left: 15px;
  width: 250px;
  border-radius: 5px;
}

.bigInput {
  width: 400px;
  height: 100px;
  margin-left: 15px;
  border-radius: 5px;
}

.followerInput {
  width: 100px;
  height: 30px;
  margin-left: 15px;
  margin-right: 10px;
}

.actionButton {
  width: 220px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #4361ee;
  font-weight: bold;
  font-family: 'Poppins';
  font-size: 18px;
}

.zaTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: -20px;
}

.dataRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 25px;
  width: 95vw;
}
@media (max-width : 700px) {
  .dataRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.dataBox {
  width: 290px;
  height: 160px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
}

.landingCTA {
  background-color: #4361ee;
  font-weight: bold;
  font-family: 'Poppins';
  border-radius: 5px;
}

.heroSection, .valueProp {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 97vw;
}

.tile {
  width: 100%;
  max-width: 500px;
  border-radius:13px;
  margin: 10px;
}

.withoutWith {
  font-size: 19px;
  font-weight: bold;
}

.tiles-container {
  display: flex;
    flex-wrap: wrap; /* Allows wrapping of the tiles if they don't fit on the same row */
    justify-content: center; /* Optional: Centers the tiles within the container */
    margin: 0 auto; /* Optional: Center the container itself */
}

.menuRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 96vw;
}

.rightButtons {
  align-items: center;
  display: flex;
  
}

.manageSub {
  width: 175px;
  margin-right: 15px;
}

.signOut{
  color: red;
  padding-right: 15px;
  cursor: pointer;
}

.logo {
  font-size: 25px;
  font-weight: bold;
  padding-left: 15px;
  cursor: pointer;
}

.homeStructure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100vw;
  height: 100vh;
}

.addCampainTile {
  width: 70%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 15px;
  padding: 20px;
  background-color: white;
}

textarea{
  font-family: 'Poppins'
}

.campainDescTile {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  width: 70%;
  max-width: 400px;
}

.video-list {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next line if no more space */
  justify-content: space-between; /* Evenly distributes space between items */
  gap: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.video-item {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  max-width: 250px;
  margin: auto;
}

.video-item h3 {
  margin-bottom: 10px;
}

.theButton {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.audios {
  width: 96%;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next line if no more space */
  justify-content: space-between; /* Evenly distributes space between items */
  gap: 20px;
  align-self: center;
}

.homeContent{
  width: 96vw;
}

.leadMagnetInput{
  padding: 10px;
  font-family: 'Poppins';
  border-radius: 5px;
  margin-left: 10px;
  width: 200px;
}

.questionStyle {
  font-family: 'Poppins';
  font-size: 22px;
}

.choice-container{
  background-color: white;
  height: 70px;
  align-items: start;
  align-content: center;
  border-radius: 15px;
  border: 1px solid rgb(183, 183, 183) ;
  margin: 10px;
  width: 95vw;
  max-width: 500px;
}

.choice-container.selected {
  border: 2px solid #6930c3;
}

#email{
  width: 90%;
  padding: 20px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #6930c3;
  border-radius: 100px;
  box-sizing: border-box;
}

.quizTitle{
  font-weight: bold;
  font-size: 26px;
  font-family: 'Poppins';
  margin-bottom: 20px; /* Ensure spacing between title and question */
  text-align: center;
}

.quiz-page {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: space-between; 
  /* min-height: 95vh;  */
  box-sizing: border-box;
}


.nextButton {
  width: 90%;
  height: 60px;
  border-radius: 15px;
  font-size: 22px;
  font-weight: bold;
  color: #eff2f0;
  font-family: 'Poppins', 'sans-serif';
  background-color: #6930c3;
  border: none;
}

.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 500px;
  text-align: center;
  min-height: 500px; 
  padding-top: 50px; 
  position: relative;
}

.quiz-container h2 {
  position: relative;
  top: 0;
  margin-top: 210px; 
}


.pageContent{
  padding-left: 30px;
  padding-right: 30px;
}


.nextButton {
  width: 90%;
  height: 60px;
  border-radius: 15px;
  font-size: 22px;
  font-weight: bold;
  color: #eff2f0;
  font-family: 'Poppins', 'sans-serif';
  background-color: #6930c3;
  border: none;
  margin-bottom: 20px; /* Add spacing between Next button and LeadCollect button */
}

.poweredBy {
  text-align: center;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px; 
  margin-bottom: 10px;
  font-family: "Poppins";
}

.title-container {
  width: 100%; 
  text-align: center;
  margin-bottom: 20px; /* More spacing below */
  padding: 10px 0; /* Adds space around */
  background-color: red; /* Just for debugging */
}


.heroTitle {
 font-size :39px;
  font-weight:bold;
  text-align:center;
  margin: 0;
  margin-bottom: 15px;
}
.objective {
  margin: 0;
  color: red;
}

@media (max-width: 775px)  {
  .heroTitle {
    font-size: 27px;
  }
}

.subSubTitle {
  font-weight: bold;
  font-size: 22px;
}

.badList {
  text-align: justify;
  font-style: italic;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 300;
}

.copyText {
  font-family: 'Inter', sans-serif;
  text-align: justify;
  font-size: 20px;
  font-weight: 300;
}

.emphasisSpan {
  background-color: yellow;
}

.subTitle {
  font-size: 30px;
  font-weight: bold;
}

.valueProp {
  width: 100%;
  background-color: #1b263b;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.processTile, .whatWeDoTile{
  max-width: 500px;
  width: 90%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 2px solid #4CAF50;
  background-color: #0d1b2a;
  margin-bottom: 15px;
}

.whatWeDoTile {
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.6);
}

.processText {
  font-size: 20px;
  font-weight: 700;
  font-family: 'Inter';
  margin: 5px;

}

.processSubText {
  font-size: 18px;
  color: rgb(232, 232, 232);
  font-family: 'Inter';
  margin: 5px;
}

.whatWeDo {
  font-family: 'Inter';
  font-size: 22px;
  font-weight: 700;
  color: #9acd32;
}

.pricing-tile {
  max-width: 400px;
  width: 90%;
  background-color: #0d1b2a;
  color: white;
  padding: 20px;
  border-radius: 15px;
  border: 2px solid #4CAF50;
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.5);
  text-align: center;
  font-family: 'Inter';

}

.total-value {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.highlight {
  color: #4CAF50;
}

.offers, .bonuses {
  margin-bottom: 15px;
}

.offer-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.bonuses h3 {
  margin-bottom: 10px;
}

.value {
  color: #9ef01a;
  font-weight: bold;
}

.pricing-details {
  margin-top: 20px;
}

.normal-price {
  text-decoration: line-through;
  color: rgb(255, 59, 59);
  font-size: 18px;
}

.limited-time {
  font-size: 18px;
  font-weight: bold;
}

.titleStytle{
  font-size: 24px;
  font-weight: bold;
  font-family: 'Poppins';
}

.faq-container {
  max-width: 600px;
  margin: 0 auto;
}

.faq-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 0;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 5px;
  margin-right: 5px;
  font-family: 'Inter';
}

.faq-item.open {
  border-color: #007bff;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  font-family: 'Inter';
}

.faq-icon {
  font-size: 13px;
  color: grey;
}

.faq-answer {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  text-align: justify;
  font-family: 'Inter';
}

.logo-container {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1000; /* Make sure it stays on top */
}

.quiz-logo {
  height: 50px; /* Adjust as needed */
  width: auto;
  cursor: pointer; /* Make it clear it's clickable */
}

.privacy-policy-container {
  max-width: 800px;
  background: white;
  padding: 20px;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.privacy-policy-container h1, h2 {
  color: #2c3e50;
}

.privacy-policy-container h3 {
  color: #34495e;
}

.privacy-policy-container p { 
  margin: 10px 0;
}

.privacy-policy-container ul {
  padding-left: 20px;
}

.privacy-policy-container strong {
  color: #d35400;
}

.vslContainer{
  max-width: 95%;
  width: 700px;
  aspect-ratio: 16 / 9;
  margin-bottom: 20px;
}

.vsl {
  width: 100%;
  height: 100%;
}